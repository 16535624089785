<template>
<!-- Ce composant est utilisé dans l'édition des récoltes et des activités de transport -->
  <div>
    <!-- Entrepôt -->
    <h4 v-if="isOnHarvest" class="subtitle-form">{{ $t("harvestStorage.list.warehouse") }}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select mb-2"
      label="name"
      :options="warehouses"
      :value="this.warehouseSelected"
      @input="(selectedInput) => checkWarehouse(selectedInput)"
      :required="isOnHarvest"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/harvestStorage/edit/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import LocationModule from "@/store/modules/location";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["location"]) {
        store.registerModule("location", LocationModule);
      }
    }
  },
  data() {
    return {
      warehouseSelected: {},
      index: "",
      loadComponent: false,
      isOnHarvest: null,
    };
  },
  components: {
    Dropdown,
  },
  async mounted() {
    this.loadComponent = false;
    this.isOnHarvest = this.$route.name.includes('harvestStorage');
    await this.$store.dispatch("location/getWarehouses");
    // Si on modifie une récolte, on pré remplit le champ entrepot
    if (this.$route.name === "harvestStorage.edit") {
        this.index = this.warehouses.findIndex((element) => element.id == this.currentEntry.location);
        if (this.index != -1) this.warehouseSelected = this.warehouses[this.index];
    }
    // Si on modifie une activité de transport où est définie une récolte, le champ entrepot est pré rempli
    if (this.$route.name === "activity.edit" && this.harvests.length > 0) {
      this.index = this.warehouses.findIndex((element) => element.id == this.harvests[0].location);
        if (this.index != -1) this.warehouseSelected = this.warehouses[this.index];
    }
    this.loadComponent = true;
  },
  methods: {
    /**
     * @description stocke l'entrepôt sélectionné dans l'attribut warehouseSelected
     */
    checkWarehouse(value) {
      this.warehouseSelected = value;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  computed: {
    ...mapGetters({
      currentHarvest: "harvestStorage/currentEntry",
      warehouses: "location/warehouses",
      harvests: "harvestStorage/harvests",
    }),
  },
  watch: {
    warehouseSelected: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("harvestStorage/setEditedEntryValue", { location: this.warehouseSelected.id });
      },
    },
  },
};
</script>

<style></style>
